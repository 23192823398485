.HeaderFooterPanel .panel-table .panel-body{
    padding:0;
}

.HeaderFooterPanel table {
    margin-bottom: 0px

}

.HeaderFooterPanel .panel-table .panel-body .table-bordered{
    border-style: none;
    margin:0;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    text-align:center;
    width: 100px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type,
.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    border-right: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:first-of-type,
.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:first-of-type {
    border-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
    padding-left: 15px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th {
    padding-left: 15px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    padding-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    padding-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
    border-bottom: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
    border-top: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr:last-of-type > td{
    border-bottom: 0px;
}


.HeaderFooterPanel .panel-table .panel-footer .pagination{
    margin:0;
}

.HeaderFooterPanel .panel-table .panel-footer .col{
    line-height: 34px;
    height: 34px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td{
    line-height: 34px;
}
