
.Navigation .divide-nav{
  margin-top: 36px;
  height: 50px;
  width: 100%;
  background-color: #428bca;
}

.Navigation .divide-text{
    color:#fff;
    line-height: 20px;
    font-size:20px;
    padding: 15px 0;
}

.Navigation .fa {
    backface-visibility: hidden !important;
}

/* navbar */
.Navigation .navbar-default {
    background-color: #333333;
    border-color: #333;
}
/* Title */
.Navigation .navbar-default .navbar-brand {
    color: #fff;
}
.Navigation .navbar-default .navbar-brand:hover,
.Navigation .navbar-default .navbar-brand:focus {
    color: #5E5E5E;
}

.Navigation .navbar-default .navbar-brand {
    padding: 10px 15px !important;
}


/* Link */
.Navigation .navbar-default .navbar-nav > li > a {
    color: #fff;
}
.Navigation .navbar-default .navbar-nav > li > a:hover,
.Navigation .navbar-default .navbar-nav > li > a:focus {
    color: #c1c1c1;
}
.Navigation .navbar-default .navbar-nav > .active > a,
.Navigation .navbar-default .navbar-nav > .active > a:hover,
.Navigation .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #f5f5f5;
}
.Navigation .navbar-default .navbar-nav > .open > a,
.Navigation .navbar-default .navbar-nav > .open > a:hover,
.Navigation .navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #333;
}
/* Caret */
.Navigation .navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.Navigation .navbar-default .navbar-nav > .dropdown > a:hover .caret,
.Navigation .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #c1c1c1;
    border-bottom-color: #c1c1c1;
}
.Navigation .navbar-default .navbar-nav > .open > a .caret,
.Navigation .navbar-default .navbar-nav > .open > a:hover .caret,
.Navigation .navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #FFF;
    border-bottom-color: #FFF;
}
/* Mobile version */
.Navigation .navbar-default .navbar-toggle {
    border: none;
 outline: none;

}
.Navigation .navbar-default .navbar-toggle:hover,
.Navigation .navbar-default .navbar-toggle:focus {
    background-color: #333;
}
.Navigation .navbar-default .navbar-toggle .icon-bar {
    background-color: #FFF;
}
.menu_dropdown {
    padding-top: 10px;
}

@media (max-width: 768px) {
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
          color: #c1c1c1;
    }
}

@media (max-width: 768px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}
}
