@media all {

    .ResetPassword {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .ResetPassword form {
        margin: 15px auto;
    }

    .ResetPassword img {
        margin: 25px auto;
    }

    .ResetPassword .panel {
        padding: 10px;
        margin-top: 40px;
    }
}
