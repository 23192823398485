.FactSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.FactSnippet .page-header {
    margin-top: 15px;
}

.FactSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.FactSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.FactSnippet #previewButton {
    margin-right: 0;
}

.FactSnippet .header-button {

    margin-right: 5px !important;
}
