.UserPlayers {
	margin-top: 35px;
	padding: 0px 10px 0px 10px;
}

.UserPlayers .page-header {
	margin-top: 15px;
}

.UserPlayers h1 > .btn {
	float: right;
	position: relative;
	right: 0px;
	bottom: 5px;
	top: 3px;
}
