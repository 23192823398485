
/* Custom Colored Panels */

.DashboardTile .huge {
    font-size: 40px;
}

.DashboardTile .panel-green {
    border-color: #5cb85c;
}

.DashboardTile .panel-green > .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.DashboardTile .panel-green > a {
    color: #5cb85c;
}

.DashboardTile .panel-green > a:hover {
    color: #3d8b3d;
}

.DashboardTile .panel-red {
    border-color: #d9534f;
}

.DashboardTile .panel-red > .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.DashboardTile .panel-red > a {
    color: #d9534f;
}

.DashboardTile .panel-red > a:hover {
    color: #b52b27;
}

.DashboardTile .panel-yellow {
    border-color: #f0ad4e;
}

.DashboardTile .panel-yellow > .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.DashboardTile .panel-yellow > a {
    color: #f0ad4e;
}

.DashboardTile .panel-yellow > a:hover {
    color: #df8a13;
}

.DashboardTile .panel-turquoise {
    border-color: #00acb8;
}

.DashboardTile .panel-turquoise > .panel-heading {
    border-color: #00acb8;
    color: #fff;
    background-color: #00acb8;
}

.DashboardTile .panel-turquoise > a {
    color: #00acb8;
}

.DashboardTile .panel-turquoise > a:hover {
    color: #00acb8;
}

.DashboardTile .panel-bergundy {
    border-color: #f0566d;
}

.DashboardTile .panel-bergundy > .panel-heading {
    border-color: #f0566d;
    color: #fff;
    background-color: #f0566d;
}

.DashboardTile .panel-bergundy > a {
    color: #f0566d;
}

.DashboardTile .panel-bergundy > a:hover {
    color: #f0566d;
}

.DashboardTile .panel-purple {
    border-color: #ab62d0;
}

.DashboardTile .panel-purple > .panel-heading {
    border-color: #ab62d0;
    color: #fff;
    background-color: #ab62d0;
}

.DashboardTile .panel-purple > a {
    color: #ab62d0;
}

.DashboardTile .panel-purple > a:hover {
    color: #963bc4;
}


