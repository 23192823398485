.TextRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextRules #previewButton {
    margin-right: 10px;
}

.TextRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.TextRules .logicSeperator {
    margin-bottom: 20px;
}

.TextRules .header-button {

    margin-right: 5px !important;
}