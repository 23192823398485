.Page {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Page .page-header {
    margin-top: 15px;
}

.Page h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.Page #previewButton {
    margin-right: 10px;
}

.Page h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.Page .header-button {
    margin-right: 5px !important;
}