/* Side Navigation */

@media(min-width:768px) {
    .NavigationSidebar .side-nav {
        position: fixed;
        top: 51px;
        left: 196px;
        width: 196px;
        margin-left: -196px;
        border: none;
        border-radius: 0;
        overflow-y: auto;
        background-color: #f5f5f5;
        bottom: 0;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    .NavigationSidebar .side-nav>li>a {
        width: 196px;
    }

    .NavigationSidebar .side-nav li a:hover,
    .NavigationSidebar .side-nav li a:focus {
        color:#111 !important;
        outline: none;
        /* background-color: #dddddd !important; */
    }
}

@media (max-width: 768px) {
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
          color: #c1c1c1;
    }
}

.navSelect {
    margin-left: 15px;
    width:auto;
    display: block;
}

/* Link */
.NavigationSidebar .navbar-default .navbar-nav > li > a {
    color: #fff;
}
.NavigationSidebar .navbar-default .navbar-nav > li > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > li > a:focus {
    color: #c1c1c1;
}
.NavigationSidebar .navbar-default .navbar-nav > .active > a,
.NavigationSidebar .navbar-default .navbar-nav > .active > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > .active > a:focus {
    color: #111;
    /* background-color: #dddddd; */
}
.NavigationSidebar .navbar-default .navbar-nav > .open > a,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #dddddd;
}
/* Caret */
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a:hover .caret,
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #c1c1c1;
    border-bottom-color: #c1c1c1;
}
.NavigationSidebar .navbar-default .navbar-nav > .open > a .caret,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:hover .caret,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #FFF;
    border-bottom-color: #FFF;
}

.NavigationSidebar .side-nav {
    /* margin-top:2px; */
    border-right: 1px solid #dddddd;
}
.NavigationSidebar .side-nav>li>ul {
    padding: 0;
}

.NavigationSidebar .side-nav a {
    font-size: 16px !important;
    color:#666666 !important;
}

.NavigationSidebar .side-nav .active a{
    font-size: 16px !important;
    color:#111 !important;
}

.NavigationSidebar .side-nav>li>ul>l>a {
    display: block;
    padding: 10px 15px 10px 38px;
    text-decoration: none;
    color: #666666 !important;
}

.NavigationSidebar .side-nav>li>ul>li>a:hover {
    color: #111;
}

@media (max-width: 768px) {

    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

ul.side-nav {
    display: flex;
    flex-direction: column;
}

ul.side-nav .spacer {
    flex-grow: 1;
}

.terms {
    margin-left: auto;
    margin-right: auto;
    display:block;
}
