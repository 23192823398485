.Terms {
    padding-top: 100px;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.termsList {
    text-align: left;

}