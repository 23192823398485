.Organisations {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Organisations .page-header {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Organisations .header-pagination {

    margin-right: 75px !important;
    margin-top: 10px !important;

}

.Organisations h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
