@media all {
     .PlainPanel {
         /* padding: 60px 0; */
    }

    .PlainPanel {
        /* margin-top: 20px; */
    }

     .PlainPanel .panel-table .panel-footer .col{
         height: 34px;
         line-height: 34px;
    }

     .h3 {
         /* font-family: "Open Sans", sans-serif; */
         font-size: 15px;
         color: #333;
         height: 30px;
         line-height: 30px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
         line-height: 34px;
    }
     .PlainPanel .panel-table .panel-body{
         padding: 15px 0px 0px 0px
    }
     .PlainPanel .panel-table .panel-body .table-bordered{
         border-style: none;
         margin:0;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr > th {
         padding-left: 15px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
         text-align:center;
         width: 100px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
         padding-left: 15px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
         padding-left: 0px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td > a {
         text-align:center;
         margin:2px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
         border-bottom: 0px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
         /* border-top: 0px; */
    }
     .PlainPanel .panel-table .panel-footer .pagination {
         margin:0;
    }

    .PlainPanel .panel-heading > div > div> form > input {
        width:100%;
    }

    .PlainPanel .panel-heading > div > div> form > input {
        width:100%;
    }
}
