.MediaPickerModal {
    width: 70%;
}

.MediaPickerModal {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaPickerModal .page-header {
    margin-top: 15px;
}

.MediaPickerModal h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaPickerModal .upload-drop-zone {
  height: 200px;
  margin-top: 20px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.MediaPickerModal .upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}
