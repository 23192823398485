body {
    margin: 0;
    padding: 0;
    /* font-family: "Open Sans", sans-serif; */
    font-size: 14px;
    color: #2f2f2f;
    background-color: #fff;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
input[type=file] {
    width: 100%;
}
#wrapper {
    padding-left: 0;
}

#content-wrapper {
    width: 100%;
    padding: 0;
    background-color: #fff;
}

@media(min-width:769px) {
    #wrapper {
        padding-left: 196px;
    }
}
#content-wrapper {
    padding: 10px !important;
}

.label {

    font-size: 90%;
}
