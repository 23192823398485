.OrganisationPlayers {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationPlayers .page-header {
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.OrganisationPlayers .header-pagination {

    margin-right: 0px !important;
    margin-top: 10px !important;
}

.OrganisationPlayers .header-button {

    margin-right: 5px !important;
}

.OrganisationPlayers h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationPlayers h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 10px;
    bottom: 5px;
    top:3px;
}
