#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.TextLibraryTextTable thead > tr > th:last-of-type {
    text-align:center;
    width: 150px;
}

.TextLibraryTextTable table {
    margin-bottom: 0px
}