@media all {

    .Login {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Login form {
        margin: 15px auto;
    }

    .Login img {
        margin: 25px auto;
    }

    .Login .panel {
        padding: 10px;
        margin-top: 40px;
    }

    .CenteredLinks small {
        text-align: center;
    }
}
