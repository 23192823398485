#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.MediaTable thead > tr > th:last-of-type {
    text-align:center;
    width: 150px;
}

.MediaTable table {
    margin-bottom: 0px

}

.MediaTable .rules {
    margin-top: 10px;
    display: block;
    clear:both;
    text-align: right;

}

.MediaTable tr td:first-child {
    /* vertical-align: middle; */
    padding-top: 18px;
    font-weight: bold;
}

.button-container {
    position: absolute;
    bottom: 28px; /* Adjust padding from the bottom */
    left: 0; /* Align to the left side of the cell */
    width: fit-content;
}
td {
    position: relative; /* Ensures the button is positioned relative to the table cell */
}