.TextSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextSnippet .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.TextSnippet #previewButton {
    margin-right: 0;
}

.TextSnippet .header-button {

    margin-right: 5px !important;
}

.TextSnippet .header-button {

    margin-right: 5px !important;
}