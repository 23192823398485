.CreateFact {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateFact .page-header {
    margin-top: 15px;
}

.CreateFact h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
