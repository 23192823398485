@media all {

    .Engineers .page-header {
        margin-top: 15px;
    }
    
    .Engineers {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Engineers form {
        margin: 15px auto;
    }

    .Engineers img {
        margin: 25px auto;
    }

    .Engineers .panel {
        padding: 10px;
        margin-top: 40px;
    }

    .CenteredLinks small {
        text-align: center;
    }

    .Engineers h1  {
        text-align: center;
    }
    .Engineers .mainHeading {
        color: #DA3C7C;
    }

    .Engineers .breadcrumb {
        /* padding: -5px; */
        margin-left: 5px;
        margin-right: 5px;
    }

    .Engineers .PaginationPanel .panel-default {
        padding: 0px;
        margin: 5px;
        margin-top: -5px;
    }

    .Engineers .logout {
        margin-left: 5px;
        text-align: center;
    }

    .Engineers .PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
        padding-left: 10px;
    }
}
