.editor-wrapper {
	margin: 0;
	font-family: Arial, sans-serif;
}

.editor-wrapper * {
	box-sizing: border-box;
}

/* Canvas Styles */
#template-snippet-canvas-wrapper {
	background: #ccc;
	padding: 5rem;
	overflow: scroll;
	height: 80vh;
	position: relative;
}

#template-snippet-canvas {
	box-sizing: border-box;
	background: #ffffff;
}

/* Menu Styles */
#template-snippet-menu {
	padding: 1rem;
	background: #f1f1f1;
	border-radius: 1rem;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

textarea:focus {
	border: solid 3px yellow;
}

.ts-menu-group {
	text-align: center;
	font-size: 1.75rem;
	width: 100%;
}

.ts-menu-col {
	padding: 0.25rem;
}

.ts-menu-row button,
.ts-menu-row input {
	margin: 0;
}

.ts-menu-row {
	margin: 0;
	padding: 0;
	width: 100%;
}

.editor-wrapper .p-0 {
	padding: 0;
}

.editor-wrapper .btn {
	padding: 0.8rem 1.2rem;
}

.editor-wrapper .btn-dark {
	background: #343a40;
	color: #fff;
}

.editor-wrapper .btn-dark.active {
	background: #1e2124;
}

.editor-wrapper .btn-dark:hover {
	background: #23272b;
	color: #fff;
}

.editor-wrapper .btn-fw {
	font-size: 1.5rem;
	font-weight: 700;
}

.ts-menu-row form {
	height: 100%;
}

.ts-menu-row form input[type='number'] {
	font-size: 1.2rem;
	height: 38px;
}

.ts-label {
	padding: 1.5rem;
	border-radius: 0.75rem;
	cursor: pointer;
	border: 1px solid transparent;
}

.ts-label.last {
	background: #f4f4f4;
	border: 1px solid #bbb;
}

.ts-label.last:hover {
	background: #ececec;
}

#modal-form-wrapper {
	margin: 0;
	background: #ddd;
	border-radius: 10px;
	padding: 2rem 1rem;
}

#modal-bg-picker {
	margin: 1rem 0;
	padding: 2rem 1rem;
	border-radius: 10px;
	background: #ddd;
	overflow: auto;
	max-height: 500px;
}

.modal-bg-thumbnail-group {
	display: flex;
	flex-wrap: wrap;
}

.modal-bg-thumbnail {
	display: table;
	margin: 1rem;
	padding: 0.5rem;
	background: white;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
	max-height: 120px;
	cursor: pointer;
}

.modal-bg-thumbnail:hover {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}

.modal-bg-thumbnail.selected {
	background: #5cb85c;
}

.modal-80 {
	width: 60vw;
}

.color-picker-tile-wrapper {
	padding: 0;
}

.color-picker-tile {
	padding: 1.5rem;
	border-radius: 5px;
	border: 2px solid #f1f1f1;
}

.color-picker-tile:hover {
	border: solid 2px #343a40;
}
