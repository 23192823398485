.MediaLibraryRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaLibraryRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.MediaLibraryRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaLibraryRules #previewButton {
    margin-right: 10px;
}

.MediaLibraryRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaLibraryRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.MediaLibraryRules .logicSeperator {
    margin-bottom: 20px;
}