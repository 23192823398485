#editButton {
	margin-left: 10px;
}

#deleteButton {
	margin-right: 5px;
}

#players-btn-cell {
	margin: 0;
	padding: 5px;
}
